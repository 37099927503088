<template>
<b-overlay :show="!order.uuid" variants="transparent" opacity="0.4" rounded="sm">
  <KTCard class="order">
    <template #body>
      <div class="d-flex">
        <!--begin: Info-->
        <div class="flex-grow-1">
          <b-container fluid class="p-0">
            <b-row align-v="center">
              <b-col cols="6">
                <h5 class="mb-0">{{ $t('ORDERS.TITLE') }} {{ order.num }}</h5>
              </b-col>
              <b-col cols="6" class="text-right">
                <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="text-decoration-none p-0"
                  no-caret
                  right
                  no-flip
                >
                  <template #button-content>
                    <div class="label label-xl label-inline pr-0" :class="`label-${getColorStatus}`">
                      {{ $t(`ORDER.STATUSES.${order.status}`) || $t('BASE.NOT_SELECTED') }}
                      <div class="btn btn-icon btn-sm btn-dropdown p-0">
                        <span class="svg-icon svg-icon-sm svg-icon-light">
                          <inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
                        </span>
                      </div>
                    </div>
                  </template>
                  <b-dropdown-item
                    v-for="status in statuses"
                    :key="status.val"
                    :active="status.val === order.status"
                    @click="changeStatus(status.val)"
                    active-class="info"
                  >
                    {{ $t(`ORDER.STATUSES.${status.val}`) }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </b-container>
          <div class="d-flex flex-wrap my-2">
            <router-link
              v-if="order.createdBy"
              :to="{ name: 'editBusinessUser', params: { id: order.createdBy.uuid }}"
              class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
            >
              <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                <inline-svg src="/media/svg/icons/General/User.svg" />
              </span>
              {{ order.createdBy.firstName }} {{ order.createdBy.lastName }}
            </router-link>
            <div class="text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
              <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                <inline-svg src="/media/svg/icons/Clothes/Shirt.svg" />
              </span>
              {{ companyUserRole }}
            </div>
            <div v-if="order.branch" class="text-muted font-weight-bold">
              <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                <inline-svg src="/media/svg/icons/Map/Marker1.svg" />
              </span>
              {{ order.branch.name }}
            </div>
          </div>
          <b-container fluid class="p-0">
            <b-row align-v="center">
              <b-col cols="12" md="6" xl="8">
                <span class="font-weight-bold text-dark-50">
                  {{ order.comment }}
                </span>
              </b-col>
              <b-col cols="12" md="6" xl="4">
                <div class="d-flex justify-content-end mx-n3">
                  <div class="flex-grow-1 flex-sm-grow-0 text-md-right px-3">
                    <div class="text-md-right font-weight-bold mb-2">{{ $t('ORDER.START_DATE') }}</div>
                    <DateSinglePickerUI
                      class="d-block d-md-inline-block"
                      v-model="order.createdAt"
                      :opens="$store.getters.isMobile ? 'right' : 'left'"
                      @update="onChangeStartDate"
                    >
                      <template #selected>
                        <div class="d-block d-md-inline-block btn btn-light-primary btn-sm font-weight-bold" style="min-width: 120px;">
                          {{ order.createdAt ? $moment(order.createdAt).format('DD MMM, yyyy') : $t('DATEPICKER.NO_DATE_SELECTED') }}
                        </div>
                      </template>
                    </DateSinglePickerUI>
                  </div>
                  <div class="flex-grow-1 flex-sm-grow-0 text-md-right px-3">
                    <div class="text-md-right font-weight-bold mb-2">{{ $t('ORDER.DUE_DATE') }}</div>
                    <DateSinglePickerUI
                      class="d-block d-md-inline-block"
                      v-model="order.deadline"
                      opens="left"
                      @update="onChangeEndDate"
                    >
                      <template #selected>
                        <div class="d-block d-md-inline-block btn btn-danger btn-sm font-weight-bold" style="min-width: 120px;">
                          {{ order.deadline ? $moment(order.deadline).format('DD MMM, yyyy') : $t('DATEPICKER.NO_DATE_SELECTED') }}
                        </div>
                      </template>
                    </DateSinglePickerUI>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <!--end: Info-->
      </div>
      <div class="separator separator-solid my-7" />
      <b-container fluid class="p-0">
        <b-row>
          <b-col cols="12" xl="6" class="py-3 py-xl-0">
            <b-container fluid class="p-0">
              <b-row>
                <b-col cols="4">
                  <div class="d-flex flex-column flex-sm-row align-items-center flex-lg-fill my-1">
                    <span class="svg-icon svg-icon-primary svg-icon-3x mr-0 mr-sm-4">
                      <inline-svg src="/media/svg/icons/Clothes/T-Shirt.svg" />
                    </span>
                    <div class="d-flex flex-column text-dark-75 align-items-center align-items-sm-center">
                      <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.ITEMS') }}</span>
                      <span class="text-dark-75 font-weight-bolder font-size-lg">{{ order.quantities ? order.quantities.itemsCount : 0 }}</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="d-flex flex-column flex-sm-row align-items-center flex-lg-fill my-1">
                    <span class="svg-icon svg-icon-primary svg-icon-3x mr-0 mr-sm-4">
                      <inline-svg src="/media/svg/icons/Tools/Roller.svg" />
                    </span>
                    <div class="d-flex flex-column text-dark-75 align-items-center align-items-sm-center">
                      <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.SERVICES') }}</span>
                      <span class="text-dark-75 font-weight-bolder font-size-lg">{{ order.quantities ? order.quantities.servicesCount : 0 }}</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="d-flex flex-column flex-sm-row align-items-center flex-lg-fill my-1">
                    <span class="svg-icon svg-icon-primary svg-icon-3x mr-0 mr-sm-4">
                      <inline-svg src="/media/svg/icons/Shopping/Box2.svg" />
                    </span>
                    <div class="d-flex flex-column text-dark-75 align-items-center align-items-sm-center">
                      <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.PRODUCTS') }}</span>
                      <span class="text-dark-75 font-weight-bolder font-size-lg">{{ order.quantities ? order.quantities.productsCount : 0 }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-col cols="12" xl="6" class="py-3 py-xl-0">
            <b-container fluid class="p-0">
              <b-row>
                <b-col cols="4">
                  <div class="d-flex flex-lg-fill my-1">
                    <div class="d-flex flex-column text-dark-75">
                      <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.SETUP.TOTAL') }}</span>
                      <span class="text-dark-75 font-weight-bolder font-size-lg">{{ order.costWithoutDiscount | centsToDollars }}</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="d-flex flex-lg-fill my-1">
                    <div class="d-flex flex-column text-dark-75">
                      <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.SETUP.COST_WITH_DISCOUNT') }}</span>
                      <span class="text-dark-75 font-weight-bolder font-size-lg">{{ order.costWithDiscount | centsToDollars }}</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="d-flex flex-lg-fill my-1">
                    <div class="d-flex flex-column text-dark-75">
                      <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.PREPAYMENT') }}</span>
                      <span class="text-dark-75 font-weight-bolder font-size-lg">
                        {{ orderPaymentsTotal | centsToDollars }}
                      </span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </KTCard>
</b-overlay>

</template>

<script>
import { mapState } from 'vuex'
import { FETCH_ORDER } from '@/core/services/store/order.module'
import RepoOrder from '@/core/repository/company/ordersRepository'
import KTCard from '../../view/content/Card'
import { STATUSES } from '../../constants/statuses-order'
import DateSinglePickerUI from '../UI/DateSinglePickerUI'

export default {
  name: 'OrderEditHeader',
  components: {
    DateSinglePickerUI,
    KTCard,
  },
  data() {
    return {
      statuses: STATUSES,
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.item,
    }),
    orderPaymentsTotal() {
      if (this.order && this.order.payments) {
        return this.order.payments.reduce((acc, currentItem) => acc + currentItem.sum, 0)
      }

      return 0
    },
    getColorStatus() {
      const obj = this.statuses.find((f) => f.val === this.order.status)
      if (obj) return obj.variant
      return 'light'
    },
    companyUserRole() {
      return this.order?.createdBy?.roles?.length ? this.$t(`COMPANY_USER.ROLES.${this.order.createdBy.roles[0]}`) : ''
    },
  },
  methods: {
    changeStatus(val) {
      RepoOrder.patch(this.$route.query.order, { status: val }).then(() => { this.$store.dispatch(FETCH_ORDER, this.$route.query.order) })
    },
    onChangeEndDate() {
      RepoOrder.patch(this.$route.query.order, { deadline: this.order.deadline })
        .then(() => { this.$store.dispatch(FETCH_ORDER, this.$route.query.order) })
    },
    onChangeStartDate() {
      RepoOrder.patch(this.$route.query.order, { createdAt: this.order.createdAt })
    },
  },
}
</script>

<style scoped>

</style>
